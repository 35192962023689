
.checkbox {
    cursor: pointer;
    display: inline-block;
    position: relative;
    height: 20px;
    width: 20px;
}

.checkbox input {
    display: none;
}

.checkbox.standard .element {
    width: 20px;
    height: 20px;
    border: 2px solid rgb(97, 97, 97);
    border-radius: 2px;


}

.checkbox.standard .element:before {
    transform-origin: bottom left;
    transform-origin: 20% 80%;
    scale: 0;
    height: 16px;
    width: 22px;
    border-left: 5px solid;
    border-bottom: 5px solid;
    border-color: #3c3a3a;
    position: absolute;
    border-radius: 4px;
    rotate: -45deg;

    margin-left: -4px;
    margin-top: -3px
}

.checkbox.standard input:checked+.element:before {
    scale: 1;

}


.checkbox .element:before {
    transition: .4s;
    content: "";
}

.checkbox.slider {
    display: inline-block;
    height: 20px;
    position: relative;
    width: 36px;
}

.checkbox.slider .element {
    background-color: #ccc;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
    border-radius: 17px;
}

.checkbox.slider .element:before {
    background-color: #fff;
    bottom: 3px;
    height: 14px;
    left: 3px;
    position: absolute;
    width: 14px;
    border-radius: 50%;
}

.checkbox.slider input[disabled="disabled"]+.element:before {
    background-color: #ddd;
}

.checkbox.slider input:checked+.element {
    background-color: #66bb6a;
}

.checkbox.slider input:checked+.element:before {
    transform: translateX(16px);
}

