.TableOverview {
    display: flex;
    flex-direction: column;
    height: 100%;

}



table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
 
    font-size: 16px;
    border-bottom: 1px solid #ddd;
   
}

th {
 
    font-weight: 600;
    align-items: center;
    padding: 10px 5px;
}
th>div{
    display:'flex';
    justify-content: space-between;
 
}
th i{
    font-style: normal;
}

td {
    text-align: center;
    padding: 10px;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

/*********************************************************************************/

.border-element {

    border-radius: 5px;
}


.drag-handle {
    cursor: move;
    /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    width: 33px;
    position: relative;
    padding:1px;
}

.drag-handle.active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}


.drag-row {
    cursor: pointer;
    box-shadow: none;
}

.drag-row.static:hover,.drag-row.static:active,.drag-row.static:focus {
    box-shadow: #3498db 0px 0px 4px inset;
    background: #3498db0f;
}
.drag-row.visited {
    box-shadow: #09dd3b 0px 0px 4px inset;
    background: #34db340f;
}

.drag-handle {
    cursor: move;
    /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    width: 50px;
    position: relative;
    background-color: none
}

.drag-handle:hover {
    background-color: rgba(219, 219, 219, 0.523);
}

/*********************************************************************************/
/*checkboxes*/


/*small*/


.img-cell img{
    width: 100%;
    height: 100%;
    object-fit:contain;
}
