.CMSOverview {

    height: 100%;
    width:100%
  }
  
  .tabs {
    display: flex;
    flex-direction: column;

    background-color: #eee;
    border-right: 1px solid #ccc;
  }

  .main-content {
    flex: 1;
    overflow-y: auto;
    margin-right: 10px;
  }

  
  .main-content h1 {
    margin: 0;
    font-size: 24px;
  }
  
  .main-content p {
    margin: 10px 0;
    font-size: 16px;
  }
  .tabs button,.tabs a {
    padding: 10px;
    font-size: 16px;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    text-align: center;
  }
  
  .tabs button.active,.tabs a.active {
    background-color: #ddd;
  }
  .tabs a{
    text-decoration: none;
    color:black;
  }

  @media (min-width: 740px){
    .CMSOverview {
        display:flex
      }
      .tabs {

        width: 200px;

      }

}