@import url('https://fonts.googleapis.com/css2?family=Krub:wght@400;500;700&display=swap');


:root {
    --base: rgb(255 254 253);
    --brown: rgb(70, 44, 21);
}
#root{
height:100%;
}
*,
*:before,
*:after {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}


*{
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    -ms-box-sizing: border-box !important;
    box-sizing: border-box !important;
    -webkit-font-smoothing: antialiased;
   
}
html,
body {
    width: 100%;
    height: 100%;
}

.background {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -100;
    background: var(--base);
}

.App {
    align-items: center;
    align-content: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
 height:100%;
     font-family:"Krub", serif;

}


.container {
    padding: 0px 10px 20px 10px;
}

.sect-mid {
    display: flex;

    flex-direction: column;
    align-items: center;
    max-width: 1580px;
    width: 100%;
     height: 100%; 
    margin: 0px auto;
    overflow-y: auto;
}




/*////////////////////////////////////*/

.head-mid {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255 254 253);
    z-index: -25;

}



.scroller {
    width: 300px;
    height: 100px;
    overflow-y: auto;
    scrollbar-color: rebeccapurple green;
    scrollbar-width: thin;
}

.text-overlay {
    width: 100%;
    color: rgb(70, 44, 21);
    text-align: center;
    font-weight: 900;

}

.text-overlay .title {
    font-size: calc(min(79px, 5vw) + 30px);
    text-transform: uppercase;
}

.text-overlay .subtitle {
    font-size: calc(min(28px, 1.5vw) + 15px);
    font-style: italic;
}


/*Profile image*/
.img-profile {
    padding: 20px;
    margin: 20px;
    margin: 2vh 16vw 2vw 5vw;
    opacity: 1.;
}

.case-profile-border {
    position: relative;

}

.img-profile img {
    border: 2px solid black;
    height: 30vh;
}

.img-profile-border {
    border: 2px solid black;
    padding: 20px;
}

/*React CssTransition*/
.alert-enter {
    opacity: 0;
    /*  transform: scale(0.9);*/
}

.alert-enter-active {
    opacity: 1;
    /* transform: translateX(0);*/
    transition: opacity 300ms, transform 300ms;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    /*  transform: scale(0.9);*/
    transition: opacity 300ms, transform 300ms;
}
.app-content{
    display: grid;
    grid-template-rows: fit-content(100%) auto;
    height: 100vh;
    width:100%;
}

.title{

    text-align: center;
    font-weight: 600;
    font-size: min(40px, calc(23px + 1vw));
}/*scrollbar*/

/*scrollbar*/
::-webkit-scrollbar {
    width: 16px;
}
::-webkit-scrollbar-track{
	border-radius: 10px;
	background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb{
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: #7b7575;
}/*
::-webkit-scrollbar-button {
    height: 12px;
  }
*/