
.project-images-upload{
    display:flex;
    flex-direction: column;
    padding: 20px;
    background: #efefef;
    border-radius: 2%;
}

.image-uploader{

    background: rgb(251 250 249 / 49%);
    border-radius: 4px;
    width: 100%;
    display: flex;
    border: 2px solid #ddd;
    padding: 3px;
    align-items: center;
    
}


@import "quill/dist/quill.core.css";




/***********************************/
.input-add{
    display:flex;
    align-items:center;
    justify-content: space-between;
     
    }

.input-form{
    display: flex;
    width: 96%;
    background: white;
    box-shadow: #00000059 1px 1px 2px 2px;
    margin: 5px;
    padding: 5px 15px;
    border-radius: 3px;
    display: flex;
    height: fit-content;
    
}
form{
    width: 100%;
    padding: 10px;

    height:fit-content;
}
.text-box{
    width: 100%;
    padding: 3px;
    /* display: flex; */
    align-items: center;
    margin-top: 4px;
    font-size: 15px;

}
.form-element{
    padding: 3px 6px;
    margin: 2px 8px;
}

.form-input{
    padding: 8px;
    border-radius: 20px;
    border: 2px solid #aaaaaa;
   
    font-size: 15px;
    margin-bottom: 4px;
    width: 100%;
    font-family: "Krub", serif;
}
.form-input.changed{

    box-shadow: 0px 0px 1px 2px #ecbf0a;
}
.label-title{
    padding: 3px;
    display: flex;
    align-items: center;
    margin-top: 4px;
    font-size: 15px;
}
.large-area textarea{
    display: flex;
    max-width: 100%;
    width: 100%!important;
    min-height: 150px;

    padding:10px;
}

.large-area{
    max-width: 100%;
}
.form-grid{
    display: grid;
}
.form-label{
    display: flex;
 align-items: start;
}
@media (min-width: 1200px){
    .form-grid:not(.column){

        grid-template-columns: minmax(min-content, 122px) auto;
    }
    .input-form {
        width: 85%;
    }
    .form-label{
       justify-content:space-between
    }
}

.form {
    display: flex;
    width: 100%;
    max-width: 1200px;
    justify-content: center;
    margin: 0px auto;

}



.progress-text{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
    font-weight: 900;
    color: white;
    text-shadow: 1px 1px 2px #08080878, -1px -1px 2px #08080878;
}


/*disabled*/
.form.disabled .input-form,
.form.view .input-form{
    margin: 5px;
    padding: 5px 15px;

}
.form.disabled form,
.form.view form{
    border:none;
}
.form.disabled .form-element,
.form.view .form-element{
    padding: 0px;
 margin:0px
}
.form.disabled .form-input,
.form.view .form-input{
    padding: 0px;
        background: none;
        border: none;

}