  
.icon {
    background-size: cover;
    width: 24px;
    height: 24px;
    margin:5px;
   
    cursor: pointer;
    opacity: 0.6;
    transition: opacity linear 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;

}
.icon:hover{
    opacity:1.0;
}



.button {
    display:flex;
    justify-content: center;
    padding: 10px 5px;
  }
  .button > *:not(svg){
    font-size: 16px;
    min-width: 150px;
    padding: 10px 20px;
    border:none;
    font-weight: 600;
    opacity:0.2;
    color: black;
  }

  .button.active > div{
    cursor: pointer;
    opacity:1
  }
.button >div{
    display:flex;
}
.button button{
    border: none;
    background: none;
    width: 100%;
    /* height: 100%; */
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    cursor:inherit;
    padding:none
}



  /*Standard button*/
  .button.standard >div{
    padding: 10px 20px;
    background-color: #3498db;
    border-radius: 4px;
    color:white
 
  }

.button.standard.active > div:hover,.button.standard.active  > div:active ,.button.standard.active  > div:focus  {
    background-color: #000000bd;
    font-weight: 600;

}

/*aternate button*/
.button.alternate > div{
border:1px solid black;
    border-radius: 4px;

  }
.button.alternate.active > div{
    background-color: #ffffff;

        font-weight: 600;
}
.button.alternate.active > div:hover,.button.alternate.active  > div:active ,.button.alternate.active  > div:focus  {
    background-color: #d5d5d5bd;
 
    font-weight: 600;
  

}



  .actions {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
}



.btn-container,.actions{

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }