.ReactModal__Overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.ReactModal__Content {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    width: 600px;
    max-width: 90%;
}

.ReactModal__Content h2 {
    margin: 0;
    font-size: 24px;
}

.ReactModal__Content form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.ReactModal__Content form label {
    font-size: 16px;
}

